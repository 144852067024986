import React, { useState } from 'react';
import PostList from './components/posts/PostList';

function App() {
    const [postId] = useState(1); // Utilisation d'un postId statique pour l'exemple

    return (
        <div className="App">
            <h1>Post et Traduction par Locale</h1>
            <PostList />
        </div>
    );
}

export default App;
