import React, { useEffect, useState } from "react";
import axios from "axios";

const PostsList = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Récupérer tous les posts depuis l'API Laravel
    axios
      .get("/api/posts") // Assure-toi que l'URL correspond à ton backend
      .then((response) => {
        setPosts(response.data); // Stocke les données des posts dans le state
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des posts:", error);
      });
  }, []);

  return (
    <div>
      <h1>Liste des Posts</h1>
      <ul>
        {posts.map((post) => (
          <li key={post.id}>
            <h2>
              {post.translations && post.translations[0]
                ? post.translations[0].title
                : "Titre non disponible"}
            </h2>
            <p>
              {post.translations && post.translations[0]
                ? post.translations[0].content
                : "Contenu non disponible"}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PostsList;
